import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from './layout'
import { ArrowLeft, Wave } from '../components/icons/index'
import { SEO, ProjectListItem as Project, Heading, Text } from '../components/index'

const Tags = ({ pageContext, data }) => {
  const { category } = pageContext
  const { edges: projects } = data.allMdx
  return (
    <Layout>
      <SEO title={`Projects`} />
      <div className="grid site-top">
        <div className="row pb2-xs pb2-md">
          <div className="col col-12-xs col-0-xs-offset col-8-md col-2-md-offset">
            <div className="row row-xs-middle">
              <Link to="/" style={{ marginRight: '2rem', color: '#F59CC5' }}>
                <ArrowLeft fill="#F59CC5" top="0.4rem" />
              </Link>
              <Heading level="1" size="h1" color="#F59CC5" accent={true} align="left">
                Projects
              </Heading>
            </div>
          </div>
        </div>
        <div className="row pb2-xs pb5-lg">
          <div className=" col col-8-xs col-1-xs-offset col-8-md col-2-md-offset">
            <Text size="g1" align="left">
              Filter by:{' '}
              <Link to="/projects">
                <span style={{ textTransform: 'capitalize', textDecoration: 'underline', marginRight: '1.2rem' }}>
                  {category}
                </span>

                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 2L14 14" stroke="white" strokeWidth="3" />
                  <path d="M14 2L2 14" stroke="white" strokeWidth="3" />
                </svg>
              </Link>
            </Text>
          </div>
        </div>

        <div>
          {projects.map(({ node: project }, index) => (
            <Project data={project} key={index} index={index} />
          ))}
        </div>
        {projects && projects.length > 1 && (
          <div className="row">
            <div className="col col-12-xs col-0-xs-offset col-8-md col-2-md-offset pt3-xs pt6-md pb2-md">
              <Wave />
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}
export default Tags

export const tagsQuery = graphql`
  query($category: String) {
    allMdx(filter: { frontmatter: { categories: { in: [$category] }, published: { eq: true } } }) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            headline
            categories
          }
        }
      }
    }
  }
`
